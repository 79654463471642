<template>
  <el-dialog title="打印请求" :visible.sync="visible" :close-on-click-modal="false"  width="30%">
    <el-form :model="form">
      <el-form-item label="打印原因">
        <el-input v-model="form.description"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button type="primary" @click="submitFrom">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { doPrint } from "@/api/print.js";
export default {
  components: {},
  data() {
    return {
      visible: false,
      document: {},
      share: false,
      form: {
        description: "",
        documentId: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    show(value) {
      this.form.documentId = value.id;
      this.visible = true;
    },
    submitFrom() {
      let that = this;
      let loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      doPrint(this.form).then(function (v) {
        
        if (v.code == 0) {
          loading.close();
          that.$alert("申请成功，请等待审核");
        } else {
          loading.close();
          that.$alert("服务器繁忙请重试");
        }
        that.visible = false;
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='less' scoped>
</style>