<template>
    <el-dialog
        title="文件置顶"
        :append-to-body="true"
        :close-on-click-modal="false" 
        width="500px"
        :before-close="clear"
        :visible.sync="dialogVisible">
        <div class="read-dialog">
            请设置置顶顺序：
            <el-input-number v-model="num" :min="1" :max="100" label="描述文字"></el-input-number>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="clear">取 消</el-button>
            <el-button type="primary" size="small" @click="onSure">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { documentAPI } from 'liankong-ui-api'
export default {
    data(){
        return {
            dialogVisible:false,
            num:1,
            rowsInfo:null
        }
    },
    methods:{
        show(row){
            this.dialogVisible = true;
            this.rowsInfo = row;
        },
        onSure(){
          documentAPI.myDocumentFileSort({
            sort:this.num,
            documentId:this.rowsInfo.id
          }).then(res => {
            if(res.code == 0){
                this.$message.success('操作成功！')
                this.clear();
                this.$emit('loadAndRefresh')
            }else{
                this.$message.error(res.message)
            }
          })
        },
        clear(){
            this.num = 1;
            this.rowsInfo = null;
            this.dialogVisible = false;
        }
    }
}
</script>