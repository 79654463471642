<template>
    <el-dialog
      title="新建文件"
      :visible.sync="visible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false" 
       width="400px">
      <div class="download-type">
        <el-form :model="fileRuleForm" :rules="fileRules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
            <el-form-item prop="doc">
              <el-input size="small" v-model="fileRuleForm.doc"></el-input>
            </el-form-item>
            <el-form-item prop="fileType" class="file-type">
              <el-radio-group v-model="fileRuleForm.fileType">
                  <el-radio  label="w"><img src="@/assets/img/icon-word.png" alt=""></el-radio>
                  <el-radio  label="s"><img src="@/assets/img/icon-excl.png" alt=""></el-radio>
                  <el-radio  label="p"><img src="@/assets/img/icon-ppt.png" alt=""></el-radio>
              </el-radio-group>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size='small' @click='closeDoing'>取消</el-button>
        <el-button size='small' type="primary" @click="doSubmit">确定</el-button>
      </span>
      <CreateFile ref="createFile" @createOver="createOver" />
    </el-dialog>
  </template>
  
  <script>
  import CreateFile from './components/CreateFile.vue'
  export default {
    components: {CreateFile},
    data () {
      return {
        visible: false,
        // type: "w",
        fileRuleForm: {
          doc:'',
          fileType:'w'
        },
        fileRules:{
          doc:[ { required: true, message: '请输入文件名称', trigger: 'blur' }]
        },
        viewerKey:'my'
      }
    },
    computed: {},
    watch: {
    },
    methods: {
      init(doc){
        this.visible = true
      },
      createOver(){
        this.$emit('createChangeOver')
      },
      doSubmit(){
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
              this.$store.dispatch('doc/createFile', {
                  vkey: this.viewerKey,
                  ...this.fileRuleForm
              }).then((res) => {
                if(res.code==0){
                  this.closeDoing()
                  this.$refs['createFile'].init(res.data,'')
                } else {
                  this.$message.error(res.message)
                }
             
              }).catch(() => {})
          } else {
            return false;
          }
        });              
       
      },
      closeDoing(){
        this.fileRuleForm.doc = ''
        this.visible = false
      }
    },
    created () {
  
    },
    mounted () {
  
    },
  }
  </script>
  <style lang='less' scoped>
  .download-type{
    margin-top: -20px;
   .demo-ruleForm {
     .file-type{
      .el-form-item__content {
        background: red;
          .el-radio-group{
            display: flex;
            flex-wrap: wrap;
            .el-radio {
              line-height: 2;
              width: 27%;
              display: flex;
              align-items: center;
             /deep/ .el-radio__label{
                display: flex;
                align-items: center;
              }
              img {
                width: 26px;
                height: 26px;
              }
            }
          }
         
        }
      }
    }
  }
  </style>