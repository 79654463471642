<template>
    <div>
        <el-dialog
        title="标签管理"
        :close-on-click-modal="false" 
        :visible.sync="disableHidden">
            <div class="label-form-dialog">
                <el-form :model="ruleForm" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="选择标签:">
                        <el-select v-model="ruleForm.ids" multiple placeholder="请选择" size="small" >
                            <el-option-group
                            v-for="group in options"
                            :key="group.label"
                            :label="group.label">
                            <el-option
                                v-for="item in group.options"
                                :key="item.id"
                                :label="item.tagName"
                                :value="item.id">
                            </el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                </el-form>

                <el-form label-width="80px" class="demo-ruleForm-manager">
                    <el-form-item label="标签管理:">
                        <el-input v-model="tagName" placeholder="请输入标签名称" size="small" v-if="isShowIpt"></el-input>
                        <el-button size="small" type="primary" @click="onAddTagLabel">{{isShowIpt ? '添加' : '添加标签'}}</el-button>
                    </el-form-item>
                    <el-form-item>
                        <div class="dialog-tag">
                            <el-tag type="info" v-for="(tagItem, ind) in tableData" :key="ind">
                                {{tagItem.tagName}}
                                <i class="el-icon-close" @click="deleteLabels(tagItem.id)"></i>
                            </el-tag>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleEvents">取 消</el-button>
                <el-button size="small" type="primary" @click="onSubmits">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { dictAPI } from 'liankong-ui-api' 
export default {
    data() {
        return {
            disableHidden: false,
            ruleForm: {
                ids: []
            },
            tableData: [],
            query: {
                pageNum: 1,
                pageSize: 999999
            },
            rowInfo: {},
            fxiedLabelDatas: [],
            options: [],
            tagName: '',
            isShowIpt: false, // 是否显示输入框
        }
    },
    methods: {
        init(row){
            this.rowInfo = row;
            this.ruleForm.ids = [];
            this.getTagAllList();
            this.getFxiedLabelList();

            this.formatArrays();

            this.lookDocTagInfo();
        },
        // 标签列表
        getTagAllList(){
            dictAPI.getTagList(this.query).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 固定标签列表
        getFxiedLabelList(){
            dictAPI.getSystemLabelList({
                pageNum: 1,
                pageSize: 999999
            }).then(res=>{
                if(res.code == 0) {
                    this.fxiedLabelDatas = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        formatArrays(){
             setTimeout(()=>{
                this.options = []
                let newObj = {
                    label: '系统标签',
                    options: this.fxiedLabelDatas
                }
                let newObj1 = {
                    label: '自定义标签',
                    options: this.tableData
                }
                this.options.push(newObj, newObj1);
            }, 1000)
        },
        // 查看被分配的标签
        lookDocTagInfo(){
            this.ruleForm.ids = []
            dictAPI.lookDocTag(this.rowInfo.id).then(res=>{
                if(res.code == 0) {
                    res.data.forEach(tagItem=>{
                        this.ruleForm.ids.push(tagItem.id)
                    })
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 添加标签
        onAddTagLabel(){
            this.isShowIpt = true;
            if(this.tagName && this.isShowIpt) {
                dictAPI.addTagData({
                    tagName: this.tagName
                }).then(res=>{
                    if(res.code == 0) {
                        this.$message.success('添加成功');
                        this.isShowIpt = false;
                        this.tagName = '';
                        this.getTagAllList();
                        this.formatArrays();
                    } else {
                        this.$message.error(res.message);
                        this.isShowIpt = false;
                        this.tagName = '';
                    }
                }).catch(error=>{
                    this.$message.error(error.message);
                    this.isShowIpt = false;
                    this.tagName = '';
                })
            }
        },
        // 删除标签
        deleteLabels(id) {
            dictAPI.removeTagData(id).then(res=>{
                if(res.code == 0) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getTagAllList();
                    this.formatArrays();
                } else {
                    this.$message.error(res.message);
                }
            }) .catch(err => {
                this.$message.error(err.message);
            })
        },
        // 确定
        onSubmits(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                   dictAPI.allocationDocTagData({
                       documentId: this.rowInfo.id,
	                    ids: this.ruleForm.ids
                   }).then(res=>{
                       if(res.code == 0) {
                           this.$message.success('绑定成功');
                           this.disableHidden = false;
                       } else {
                           this.$message.error(res.message);
                           this.disableHidden = false;
                       }
                   }).catch(error=>{
                        this.$message.error(error.message);
                        this.disableHidden = false;
                   })
                }
            })
        },
        cancleEvents(){
            this.disableHidden = false;
        },
    }
}
</script>
<style lang="less" scoped>
.label-form-dialog {
    display: flex;
    /deep/ .el-form.demo-ruleForm {
        width: 50%;
    }
    /deep/ .el-form.demo-ruleForm-manager {
        width: 50%;
        .el-form-item {
            margin-bottom: 0px;
            .el-form-item__content {
                display: flex;
                align-items: center;
                .el-input {
                    margin-right: 15px;
                }
                .dialog-tag {
                    display: flex;
                    flex-wrap: wrap;
                    .el-tag {
                        margin: 6px 5px 0 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>